.MuiTablePagination-root {
  display: inline-flex;
  position: sticky;
  left: 0;
  height: 70px;
}

.MuiTablePagination-root .MuiTablePagination-spacer {
  flex: 0;
}
